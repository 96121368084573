<template>
    <div>
        <div
            class="directory-header"
            :class="[
                { 'justify-content-center': titleCentered },
                { 'align-items-center': titleCentered },
            ]"
        >
            <div>
                <h4>{{ title }}</h4>
                <p
                    v-if="description"
                    :class="[{ 'text-center': titleCentered }]"
                    v-html="description"
                />
            </div>
            <div class="btn-container" v-if="!hidePostBtn">
                <router-link
                    class="primary-button d-inline my-0"
                    :to="{ name: 'directory_listing_create' }"
                >
                    Post Directory
                    <i class="fa fa-plus ml-1"></i>
                </router-link>
            </div>
        </div>
        <hr />
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: "Directory Listing",
        },
        description: {
            type: String,
            default: "",
        },
        hidePostBtn: {
            type: Boolean,
            default: false,
        },
        titleCentered: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<style lang="scss" scoped>
.directory-header {
    display: flex;
    justify-content: space-between;
}
@media screen and (max-width: 992px) {
    .directory-header {
        flex-direction: column;
        .btn-container {
            margin: 10px 0;
        }
    }
}
</style>
