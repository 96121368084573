<template>
    <div class="contianer p-md-5 p-3">
        <PageLoader :isLoading="isLoading" />
        <DirectoryListingHeader
            title="Directory Listings"
            description="Find a variety of directory listings from different categories"
        />
        <div class="row">
            <div class="col-md-6" v-for="category in categories" :key="category.id">
                <DirectoryListingCategoryItem :category="category" />
            </div>
        </div>
    </div>
</template>

<script>
import DirectoryListingCategoryItem from "./DirectoryListingCategoryItem.vue"
import PageLoader from "../Loader.vue"
import DirectoryListingHeader from "./DirectoryListingHeader.vue"
import scrollTop from "../utils/scrollTop"
import request from "../../apis/request"

export default {
    components: { DirectoryListingHeader, DirectoryListingCategoryItem, PageLoader },
    data() {
        return {
            isLoading: false,
            categories: [],
        }
    },
    async mounted() {
        scrollTop()
        this.isLoading = true
        try {
            await this.fetchDirectoryListings()
        } catch (err) {
            console.log("Error in loading directory listings")
        }
        this.isLoading = false
    },
    methods: {
        async fetchDirectoryListings() {
            const res = await request.get("/directories/categories?sorted=true")
            this.categories = res.data.result
        },
    },
}
</script>

<style></style>
