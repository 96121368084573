<template>
    <div
        class="card card-body my-2 p-2 directory-category-listing-item"
        @click="navigateToCategory()"
    >
        <div class="title">{{ category.name }}</div>
        <div class="text-secondary small">
            Total Directory Listings: {{ category.total_directories }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        category: {
            type: Object,
            default: () => ({}),
        },
    },
    methods: {
        navigateToCategory() {
            this.$router.push({
                name: "directory_category_listing",
                params: {
                    categorySlug: this.category.slug,
                },
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.directory-category-listing-item {
    user-select: none;
    &:hover {
        background: rgb(210, 238, 249);
        cursor: pointer;
        .title {
            color: rgb(18, 82, 138);
        }
    }
    .title {
        font-size: 1.2em;
    }
}
</style>
